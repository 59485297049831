<template>
  <div class="map-size">
    <input ref="placeAutocomplete" />
    <div ref="mapRoute" class="map-size"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Route',
  props: {
    default: {
      type: Array,
      default: () => [],
    },
    readonly: Boolean,
  },
  data() {
    return {
      map: null,
      polyline: null,
      zoom: 13,
    };
  },
  mounted() {
    const center = { lat: this.getUser.latitude, lng: this.getUser.longitude };
    this.map = new window.google.maps.Map(this.$refs.mapRoute, {
      zoom: this.zoom,
      center,
    });
    this.map.addListener('click', (event) => {
      this.addPointToPolyline(event.latLng);
    });
    if (this.default.length > 0) {
      this.createPolyline(
        this.default.map((point) => ({ lat: point.latitude, lng: point.longitude }))
      );
    }
    this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.placeAutocomplete, {
      componentRestrictions: { country: 'mx' },
      fields: ['address_components', 'geometry', 'icon', 'name'],
      strictBounds: false,
    });
    this.autocomplete.addListener('place_changed', () => {
      console.log('place_changed');
      this.setPlace(this.autocomplete.getPlace());
    });
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  methods: {
    createPolyline(path) {
      if (this.polyline) {
        this.polyline.setMap(null);
      }
      this.polyline = new window.google.maps.Polyline({
        path,
        editable: !this.readonly,
        draggable: false,
        strokeColor: '#FF0000',
        strokeOpacity: 1,
        strokeWeight: 3,
      });
      this.polyline.setMap(this.map);
      window.google.maps.event.addListener(this.polyline, 'contextmenu', (e) => {
        if (e.vertex !== undefined) {
          this.polyline.getPath().removeAt(e.vertex);
        }
      });
    },
    addPointToPolyline(point) {
      if (!this.polyline) {
        this.createPolyline([point]);
      } else {
        const path = this.polyline.getPath();
        path.push(point);
      }
    },
    getItem() {
      if (!this.polyline) return [];
      return this.polyline
        .getPath()
        .getArray()
        .map((g) => ({ latitude: g.lat(), longitude: g.lng() }));
    },
    pathChange(event) {
      this.geofence = event.getArray().map((a) => ({ latitude: a.lat(), longitude: a.lng() }));
    },
    hasErrors() {
      if (!this.polyline || this.polyline.getPath().getLength() === 0) {
        window.VMA.$emit('SHOW_SNACKBAR', {
          text: 'Debes seleccionar una ubicación',
          color: 'error',
        });
        return true;
      }
      return false;
    },
    setPlace(place) {
      const placePosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.setCenter(placePosition);
    },
    setCenter(center) {
      this.map.setCenter(center)
    },
  },
};
</script>

<style scoped>
.pac-target-input {
  height: 50px !important;
  width: 100%;
  font-size: 16px;
  padding: 10px;
}
.map-size {
  width: 100%;
  height: 780px;
}
</style>
