import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store';
import RouteC from './Route.vue'

const Route = class {
  constructor(props) {
    this.route = new Vue({
      vuetify,
      store,
      ...RouteC,
      propsData: props || {}
    }).$mount()
  }

  get() {
    return this.route.$el
  }

  getItem() {
    return this.route.getItem()
  }

  hasErrors() {
    return this.route.hasErrors()
  }
}

export default Route
