<template>
  <v-text-field
    v-model="value"
    :type="type"
    :name="name"
    :label="label"
    :error="error"
    :loading="isLoading"
    :error-messages="message"
    :prepend-icon="icon"
    append-icon="mdi-map"
    :readonly="true"
    :min="min"
    @change="removeError"
    autocomplete="off"
    v-show="show">
      <template v-slot:label>
        <span v-if="!optional" class="red--text">*</span> {{ label }}
      </template>
      <template v-slot:append>
        <v-btn
          depressed
          tile
          color="primary"
          class="ma-0"
          @click="select">
          <v-icon right dark style="margin-right: 8px;">mdi-map</v-icon>
        </v-btn>
      </template>
  </v-text-field>
</template>

<script>
import Dialog from '@/components/dialog'
import Route from '@/components/route'

export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: Array,
    readonly: Boolean,
    optional: Boolean,
    min: Number,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    decimals: Boolean,
    fields: Array,
    visibility: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.value = this.toString(this.default)
  },
  methods: {
    select() {
      const route = new Route({
        default: this.toArray(this.value),
        readonly: this.readonly
      })
      const dialog = new Dialog({
        title: this.label,
        size: 'large',
        height: '900px',
        actions: [{
          icon: 'mdi-check',
          color: 'success',
          help: 'Aceptar',
          condition: () => !this.readonly,
          action: () => {
            this.value = this.toString(route.getItem())
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(route)
    },
    getItem() {
      return { name: this.name, value: this.toArray(this.value) }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    loading(val = false) {
      this.isLoading = val
    },
    toArray(item) {
      return item?.split(',').map((point) => {
        const w = point.trim().split(' ')
        return { latitude: parseFloat(w[0]), longitude: parseFloat(w[1]) }
      })
    },
    toString(item) {
      return item?.map((t) => {
        return t.latitude + ' ' + t.longitude
      }).join(', ')
    }
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: 'mdi-format-text',
      show: this.visibility,
      firstLoad: true,
      isLoading: false
    }
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val)
      } else {
        this.firstLoad = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-text-field {
    padding-top: 6px !important;
    margin-top: 4px !important;
  }
</style>
