<template>
  <div class="pt-2 pb-2">
    <v-card class="mx-auto" max-height="400px" style="overflow-y: hidden;" v-if="show != false">
      <v-toolbar color="secondary" dark fixed dense>
        <v-toolbar-title><span v-html="label"></span></v-toolbar-title>

        <v-spacer></v-spacer>

        <input
          ref="image"
          type="file"
          :clearable="false"
          :name="name"
          :label="label"
          @change="validateFile"
          style="display: none"
          accept="image/*"
        />
        <v-btn v-if="value" depressed tile color="red" class="ma-0 mr-2" @click="clear">
          <v-icon right color="white" style="margin-right: 8px">mdi-close</v-icon>
        </v-btn>
        <v-btn v-if="!readonly" depressed tile color="success" class="ma-0" @click="$refs.image.click()">
          <v-icon right dark style="margin-right: 8px">mdi-cloud-upload</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex justify-center mt-2 mb-2" style="max-height: 350px" v-if="value">
        <img :src="selectedFile" v-if="selectedFile"/>
        <img :src="baseApi + (value.split('/').pop())" v-else-if="value"/>
      </div>
    </v-card>
    <span v-if="message" class="error--text">{{ message }}</span>
  </div>
  <!-- <div class="d-flex flex-row">
    <v-file-input
      v-model="value"
      :label="label"
      :disabled="readonly"
      :error="error"
      :error-messages="message"
      accept="image/png"
      @change="validateFile"
      prepend-icon="mdi-file-image"
    ></v-file-input>
    <div style="height: 350px; overflow-y: auto" v-if="value && value.length > 0">
      <v-img :src="baseApi + value"></v-img>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: String,
    readonly: Boolean,
    optional: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    maxWidth: {
      type: Number
    },
    maxHeight: {
      type: Number
    },
    baseApi: {
      type: String,
      default: process.env.VUE_APP_IMAGES_PATH || '/api/v2/public/images/',
    },
    visibility: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.value = this.default || null
  },
  data() {
    return {
      value: null,
      selectedFile: null,
      message: null,
      error: false,
      icon: 'mdi-image-outline',
      show: this.visibility,
      firstLoad: true
    }
  },
  methods: {
    select(val) {
      if (!this.readonly) {
        this.value = val
      }
    },
    getItem() {
      return { name: this.name, value: this.value }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.clear()
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    clear() {
      this.value = null
      this.selectedFile = null
      this.removeError()
    },
    validateFile() {
      this.removeError()
      const image = this.$refs.image.files[0]
      if (image) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const _image = new Image();
          _image.src = event.target.result;
          _image.onload = () => {
            if (this.maxWidth && _image.width > this.maxWidth) {
              this.setError(`La imagen exdece el ancho máximo de ${this.maxWidth} px.`);
              return
            }
            if (this.maxHeight && _image.height > this.maxHeight) {
              this.setError(`La imagen exdece el ancho máximo de ${this.maxWidth} px.`);
              return
            }
            if (this.width && _image.width !== this.width) {
              this.setError(`La imagen debe ser de ${this.width} px de ancho.`);
              return
            }
            if (this.height && _image.height !== this.height) {
              this.setError(`La imagen debe ser de ${this.height} px de largo.`);
              return
            }
            this.selectedFile = event.target.result;
            this.value = image;
          }
        };

        reader.readAsDataURL(image);
      }
    }
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val)
      } else {
        this.firstLoad = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  height: 100%;
  max-height: 200px;
  max-width: 100%;
}
</style>
