<template>
  <v-text-field
    v-model="value"
    :type="type"
    :name="name"
    :label="(label + ' (lat, long)')"
    :error="error"
    :loading="isLoading"
    :error-messages="message"
    :prepend-icon="icon"
    append-icon="mdi-map"
    :readonly="readonly"
    :min="min"
    @change="removeError"
    autocomplete="off"
    v-show="show">
      <template v-slot:label>
        <span v-if="!optional" class="red--text">*</span> {{ label + ' (lat, long)' }}
      </template>
      <template v-slot:append>
        <v-btn
          depressed
          tile
          color="primary"
          class="ma-0"
          @click="select">
          <v-icon right dark style="margin-right: 8px;">mdi-map</v-icon>
        </v-btn>
      </template>
  </v-text-field>
</template>

<script>
import Dialog from '@/components/dialog'
import Location from '@/components/location'

export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: String,
    readonly: Boolean,
    optional: Boolean,
    min: Number,
    append_icon: String,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    decimals: Boolean,
    fields: Array,
    visibility: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.value = this.default
  },
  methods: {
    select() {
      const location = new Location({
        position: this.value,
        readonly: this.readonly
      })
      const dialog = new Dialog({
        title: this.label,
        size: 'large',
        height: '900px',
        actions: [{
          icon: 'mdi-check',
          color: 'success',
          help: 'Aceptar',
          condition: () => !this.readonly,
          action: () => {
            if (location.hasErrors()) {
              return
            }
            this.value = location.getItem()
            this.$parent.$parent.onChange(this.name, this.value)
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(location)
    },
    getItem(validation) {
      if (validation) {
        return { name: this.name, value: this.value }
      }
      if (!this.value) {
        return {
          [this.fields[0]]: undefined,
          [this.fields[1]]: undefined
        }
      }
      const val = this.value.split(',')
      return {
        [this.fields[0]]: parseFloat(val[0]),
        [this.fields[1]]: parseFloat(val[1])
      }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    loading(val = false) {
      this.isLoading = val
    }
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: 'mdi-format-text',
      show: this.visibility,
      firstLoad: true,
      isLoading: false
    }
  },
  // watch: {
  //   value(val) {
  //     if (!this.firstLoad || this.forceChange) {
  //       this.$parent.$parent.onChange(this.name, val)
  //     } else {
  //       this.firstLoad = false
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
  .v-text-field {
    padding-top: 6px !important;
    margin-top: 4px !important;
  }
</style>
